import * as Sentry from '@sentry/nextjs';

import axios, { type AxiosError } from 'axios';
import { type FirebaseError } from 'firebase/app';

type ErrorHandlerProps = {
  error?: Error | AxiosError | FirebaseError;
  functionName?: string;
  metaData?: {
    [key: string]: any;
  } | null;
  sentryCapture?: boolean;
  extraTags?: { key: string; value: string }[];
  severity?: Sentry.SeverityLevel;
  user?: { email?: string; id?: string };
  message?: string | undefined;
};

export default ({
  error,
  functionName = 'Unknown function: ',
  metaData = {},
  sentryCapture = true,
  user,
  extraTags,
  severity = 'error',
}: ErrorHandlerProps) => {
  if (axios.isAxiosError(error)) {
    console.error(`${functionName} an axios error occured: `, error.toJSON(), metaData);
  } else {
    console.error(functionName, 'something went wrong: ', error, metaData);
  }
  const formattedError = `${functionName} ${error?.message ?? 'Something went wrong'}`;
  if (sentryCapture) {
    Sentry.withScope(scope => {
      scope.setTag('functionName', functionName);
      scope.setLevel(severity);
      scope.setContext('aditionalData', { ...metaData });

      const fingerprintArray = [functionName];

      if (axios.isAxiosError(error)) {
        const axiosError = {
          message: error.message,
          name: error.name,
          code: error.code,
          stack: error.stack,
        };
        axiosError.code && fingerprintArray.push(axiosError.code);
        scope.setContext('axiosError', { ...axiosError });
      }

      fingerprintArray.length > 0 && scope.setFingerprint(fingerprintArray);

      if (extraTags?.length) {
        extraTags.map(({ key, value }) => {
          scope.setTag(key, value);
        });
      }

      if (user) {
        scope.setUser({
          ...user,
        });
      }

      Sentry.captureException(error ?? formattedError, () => scope);
    });
  }
  return formattedError;
};
